.container {
    padding: 100px 0;
    background-color: #5B2D80;
    background-image: url(./assets/bg-r.png), url(./assets/bg-l.png);
    background-repeat: no-repeat, no-repeat;
    background-position: bottom right, bottom left;
    background-size: 250px auto, 250px auto;
    >form {
        max-width: 700px;
        margin: 0 auto;
        background-color: #3A0068;
        padding: 45px 30px;
        border-radius: 30px;
        >h4 {
            font-size: 26px;
            text-align: center;
            color: white;
        }
        >.wrapper {
            margin-top: 40px;
            columns: 2;
            column-gap: 20px;

            >.field {
                display: flex;
                flex-flow: row wrap;
                margin-bottom: 10px;
                border-radius: 10px;
                overflow: hidden;
                >label {
                    background-color: #B377E3;
                    font-size: 12px;
                    text-transform: uppercase;
                    color: white;
                    font-weight: 600;
                    padding: 16px 10px;
                    flex: 0 1 100px;
                    &.lf {
                        flex: 1 !important;
                    }
                }
                >input, textarea {
                    min-width: 0;
                    flex: 1 1 180px;
                    min-height: 40px;
                    border-radius: 0;
                    border: 3px solid white;
                    padding: 10px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #5B2D80;
                    outline: none;

                    &::placeholder {
                        color: #B377E3;
                        font-weight: 400;
                    }
                }
                >textarea {
                    flex: 1 1 400px;
                    height: 150px;
                    resize: none;
                    &::placeholder {
                        color: #B377E3;
                    }
                }
            }
            >.buttonField {
                >button {
                    padding: 10px 25px;
                    font-weight: 600;
                    font-size: 14px;
                    color: #5B2D80;
                    border-radius: 10px;
                    cursor: pointer;
                    outline: none;
                    border: none;
                }
            }


            @media (max-width: 600px) {
                columns: 1;
            }
        }
    }
}