.dashboard {
    padding: 30px 50px;
    background-color: #F3F3F3;

    >.inner {
        max-width: 900px;
        margin: 0 auto;

        >.profile {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 20px;

            >.avatar {
                height: 70px;
                width: 50px;

                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: center center;
                }
            }

            >.texts {
                >h3 {
                    color: var(--primary-color);
                }
            }

        }

        .dropLink {
            border-top: 1px solid #E3E3E3;
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            font-weight: 800;
            font-size: 16px;
            padding: 10px 30px;

            >img {
                height: 14px;
                object-fit: contain;
            }
        }
        >.btn {
            width: 100%;
            margin-bottom: 30px;
            justify-content: flex-start;
        }
    }
}