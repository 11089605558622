.header {
    background-color: white;
    padding: 100px;

    @media (max-width: 600px) {
        padding: 50px;
    }

    >.inner {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 50px;

        >.content {
            flex: 1 1 300px;

            >h2,
            >p {
                margin-bottom: 20px;
                color: var(--color-purple);

                >.important {
                    color: var(--primary-color);
                }

                @media (max-width: 600px) {
                    text-align: center;
                }
            }

        }

        >.image {
            flex: 1 1 300px;

            >img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

    }

    >.filters {
        max-width: 1200px;
        margin: 0 auto;
        >.row {
            max-width: 500px;
            display: flex;
            flex-flow: row wrap;
            justify-self: flex-start;
            gap: 20px;
            margin-top: 10px;

            @media (max-width: 600px) {
                >* {
                    flex: 1 1 200px;
                }
            }
        }

        @media (max-width: 600px) {
            max-width: none;
        }
    }
}

.projects {
    margin-top: 30px;
    padding: 150px;
    position: relative;

    @media (max-width: 600px) {
        padding: 100px 50px;
    }

    &::before {
        content: '';
        background-color: #F3F3F3;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: skewY(-2deg);
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        z-index: -1;
    }

    &::after {
        content: '';
        background-color: #F3F3F3;
        position: absolute;
        top: 50%;
        bottom: -30px;
        left: 0;
        right: 0;
        z-index: -1;
    }

    >.inner {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-flow: column;
        gap: 50px;

        >.itemsContainer {
            display: flex;
            flex-flow: row wrap;
            gap: 50px;

            >.noResult {
                text-align: center;
                margin: auto;
            }

            >.item {
                flex: 1 1 350px;
                height: 350px;
                position: relative;
                border-radius: 20px;
                overflow: hidden;
                cursor: pointer;

                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top center;
                }

                >.itemsHead {
                    position: absolute;
                    top: 0;
                    height: 70px;
                    background-color: rgba(100%, 100%, 100%, .2);
                    backdrop-filter: blur(4px);
                    width: 100%;
                    z-index: 5;
                    padding: 0 20px;
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    align-items: center;

                    >.soon {
                        padding: 5px 8px;
                        border-radius: 5px;
                        color: white;
                        background-color: #994AD8;
                    }
                }
            }
        }

        >.row {
            display: flex;
            flex-flow: row nowrap;
            gap: 50px;

            &.reversed {
                flex-direction: row-reverse;
            }

            >img {
                cursor: pointer;
                max-width: 100%;
                height: auto;
                max-height: 400px;

                &:first-of-type {
                    flex: 3 1 0%;
                }

                &:last-of-type {
                    flex: 2 1 0%;
                }
            }

        }

        >img {
            cursor: pointer;
            width: 100%;
            height: 450px;
        }
    }
}