:root {
  --primary-color: #0ABF8C;
  --secondary-color: #FEAF11;
  --background-color: #F3F3F3;
  --color-black: #707070;
  --color-purple: #3A0068;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  line-height: 1.2;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* min-width: fit-content; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 50px;
  font-weight: 700;
  color: #707070;
}

h2 {
  font-size: 60px;
  font-weight: 700;
  color: #707070;
}

h3 {
  font-size: 45px;
  color: #707070;
}

h4 {
  font-size: 20px;
  color: #707070;
}

h5 {
  font-size: 14px;
  color: #707070;
}

h6 {
  font-size: 12px;
  font-weight: 400;
  color: #707070;
}

p,
a {
  font-size: 20px;
  line-height: 1.5;
  color: #707070;
}

label {
  font-size: 12px;
  font-weight: 400;
  color: #707070;
}

input {
  outline: none;
}

input:focus {
  outline: 1px solid white;
}

/* 
.carouselDots {
  display: flex !important;
  flex-flow: row nowrap;
  list-style: none;
} */

.slick-arrow {
  width: 80px !important;
  height: 80px !important;
  z-index: 10 !important;
}

.slick-next {
  background: url(./nextArrow.png) center center/contain no-repeat !important;
  right: 0 !important;
}

.slick-prev {
  background: url(./prevArrow.png) center center/contain no-repeat !important;
  left: 0 !important;
}

.slick-arrow:before {
  content: '' !important;
}

.slick-dots {
  z-index: 10 !important;
}

.slick-slide {
  z-index: 8 !important;
}


@media (max-width:600px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 50px;
    font-weight: 700;
    color: #707070;
  }

  h3 {
    font-size: 35px;
    color: #707070;
  }

  h4 {
    font-size: 18px;
    color: #707070;
  }

  h5 {
    font-size: 14px;
    color: #707070;
  }

  h6 {
    font-size: 12px;
    font-weight: 400;
    color: #707070;
  }

  p,
  a {
    font-size: 16px;
    line-height: 1.5;
    color: #707070;
  }
}