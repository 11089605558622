.product {
    >.head {
        display: flex;
        padding: 14px;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        gap: 10px;

        >.discount {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: var(--secondary-color);
            color: white;
            font-size: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        >.btns {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 10px;

            >svg {
                color: #707070;

                &:hover {
                    color: black;
                }
            }
            
        }
        
    }

    >.body {
        display: block;
        height: 250px;
        // width: 100%;
        border-radius: 30px;
        overflow: hidden;
        text-decoration: none;

        >* {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    >.foot {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 14px;

        h6 {
            color: var(--secondary-color);
            line-height: 1;
        }

        h4,
        h5 {
            color: var(--primary-color);
            line-height: 1;
        }

        >.info {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            gap: 10px;

            h5 {
                font-weight: 400;
                text-decoration: line-through;
            }
        }
    }
}

.productLink {
    font-size: 14px;
    color: var(--primary-color);
    background-color: rgba(0,0,0,.1);
    padding: 5px;
    border-radius: 5px;
    min-height: 100%;
}

.miniProductCard {
    >.image {
        height: 100px;

        >* {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 20px;
        }
    }

    >label {
        color: var(--primary-color);
        padding: 10px;
    }
}

@keyframes roller {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loadingCard {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color);

    >.loadingCardRoller {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        border: 5px solid var(--primary-color);
        border-right-color: transparent;
        animation: roller 1s linear infinite;
    }
}

.failedCard {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color);

    >.row {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 10px;
        margin-bottom: 30px;
        >.icon {
            color: var(--secondary-color);
            font-size: 30px;
        }
    }

}