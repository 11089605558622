.cart {
    padding: 30px 50px;
    background-color: #F3F3F3;

    >.inner {
        max-width: 900px;
        margin: 0 auto;

        >.cartBasket {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 20px;
            margin-bottom: 50px;

            >.avatar {
                height: 70px;
                width: 50px;

                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: center center;
                }
            }

            >.texts {
                >h3 {
                    color: var(--primary-color);
                }
            }

            >.count {
                margin-left: auto;
                background-color: var(--primary-color);
                padding: 10px 20px;
                border-radius: 10px;

                >h3 {
                    color: white;
                }
            }

        }

        >.cartItem {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            background-color: white;
            border-radius: 15px;
            gap: 15px;
            padding: 15px;
            margin-bottom: 30px;

            >.image {
                position: relative;
                height: 100px;
                width: 100px;
                border-radius: 10px;
                overflow: hidden;

                >.discount {
                    width: 30px;
                    height: 30px;
                    background-color: var(--secondary-color);
                    font-size: 10px;
                    font-weight: 700;
                    color: white;
                    display: flex;
                    flex-flow: column;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    position: absolute;
                    top: 5px;
                    left: 5px;
                }

                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            >.navs {
                display: flex;
                flex-flow: column;
                margin-left: auto;
                gap: 7px;
                >* {
                    font-size: 10px;
                    font-weight: 600;
                    padding: 4px 7px;
                    border: none;
                    border-radius: 5px;
                }
                >.orange {
                    background-color: var(--secondary-color);
                    color: white;
                }
            }

            >.content {
                display: flex;
                flex-flow: column;
                gap: 7px;
                >* {
                    line-height: 1;
                }
            }

        }

    }
    >.other {
        position: relative;
    }
}

.row {
    display: flex;
    flex-flow: column;

    >.btn {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 30px;
        justify-content: flex-start;
        max-width: 900px;
    }
}