.client {
    padding: 150px;
    position: relative;
    // margin-bottom: 30px;
    margin-top: 30px;

    // background-color: white;
    &::before {
        content: '';
        background-color: #F3F3F3;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: skewY(-2deg);
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        z-index: -1;
    }

    &::after {
        content: '';
        background-color: #F3F3F3;
        position: absolute;
        top: 50%;
        bottom: -30px;
        left: 0;
        right: 0;
        z-index: -1;
    }

    @media (max-width:600px) {
        padding: 150px 50px;
    }

    >.head {
        max-width: 1200px;
        margin: 0 auto;
        margin-bottom: 100px;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 30px 50px;

        >h3 {
            color: var(--color-purple);
            flex: 1 1 200px;
        }

        >p {
            color: var(--color-purple);
            flex: 4 1 300px;
        }

        @media (max-width:600px) {

            >h3,
            >p {
                text-align: center;
            }
        }
    }

    >.items {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        max-width: 1200px;
        margin: 0 auto;
        gap: 100px 80px;

        >* {
            flex: 0 1 150px;
            height: 75px;
            object-fit: contain;
        }

        @media (max-width:600px) {
            gap: 50px 30px;

            >* {
                flex: 0 1 70px;
                height: 35px;
            }
        }
    }
}

.navigation {
    background-color: white;
    padding: 100px 30px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    gap: 30px;


    >.showInMobile {
        display: none;
    }

    @media (max-width:600px) {
        position: sticky;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        padding: 30px;
        flex-wrap: nowrap;
        z-index: 30;

        >.showInMobile {
            display: block;
        }

        a {
            min-width: 0;
        }
    }
}

.projects {
    padding: 150px 30px;
    position: relative;
    margin-bottom: -30px;
    margin-top: 30px;

    // background-color: white;
    &::before {
        content: '';
        background-color: white;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: skewY(2deg);
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        z-index: -1;
    }

    &::after {
        content: '';
        background-color: white;
        position: absolute;
        top: 50%;
        bottom: -30px;
        left: 0;
        right: 0;
        z-index: -1;
    }

    >.head {
        max-width: 1200px;
        margin: 0 auto;
        margin-bottom: 25px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        >h3 {
            color: var(--color-purple);
        }
    }

    >.carousel {
        max-width: 1200px;
        margin: 0 auto;

        .spacer {
            padding-right: 20px;

            @media (max-width:600px) {
                padding: 0;
            }
        }

        .items {
            height: 350px;
            position: relative;
            border-radius: 20px;
            overflow: hidden;
            cursor: pointer;

            >img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: top center;
            }

            >.itemsHead {
                position: absolute;
                top: 0;
                height: 70px;
                background-color: rgba(100%, 100%, 100%, .2);
                backdrop-filter: blur(4px);
                width: 100%;
                z-index: 5;
                padding: 0 20px;
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                align-items: center;

                >.soon {
                    padding: 5px 8px;
                    border-radius: 5px;
                    color: white;
                    background-color: #994AD8;
                }
            }
        }

    }
}

.testimonies {
    // background-color: transparent;
    position: relative;
    padding: 100px 0 50px;
    margin-bottom: 30px;

    &::before {
        content: '';
        background-color: #994AD8;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: skewY(-2deg);
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        z-index: -1;
    }

    &::after {
        content: '';
        background-color: #994AD8;
        position: absolute;
        top: 50%;
        bottom: -30px;
        z-index: -1;
        left: 0;
        right: 0;
    }

    >.head {
        max-width: 1200px;
        margin: 0 auto;
        margin-bottom: 100px;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 30px 50px;

        >h3 {
            color: white;
            flex: 1 1 200px;
        }

        >p {
            color: white;
            flex: 4 1 300px;
        }

        @media (max-width:600px) {

            >h3,
            >p {
                text-align: center;
            }
        }
    }

    >.carousel {
        max-width: 1200px;
        margin: 0 auto;
        padding-bottom: 30px;
        overflow: hidden !important;

        .writeup {
            display: flex !important;
            margin: auto auto;
            flex-flow: row nowrap;
            gap: 10px;
            padding: 0 30px;

            // @media (max-width: 600px) {
            //     flex-flow: column;
            //     >.head {
            //         width: 100% !important;
            //     }
            // }

            >.head {
                width: 150px;
                display: flex;
                flex-flow: column;
                height: 200px;

                >.avatar {
                    flex: 1;
                    width: 100%;
                    border-radius: 30px 30px 0 30px;
                    overflow: hidden;
                    max-height: 80%;

                    >img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                >.socials {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: center;
                    margin-top: 10px;
                    gap: 5px;

                    >* {
                        font-size: 32px;
                        color: white;
                    }
                }

                @media (max-width: 600px) {
                    width: 100px;
                }
            }

            >.content {
                flex: 1;
                max-height: 100% !important;
                overflow: hidden;
                background-color: rgba(0, 0, 0, 0.2);
                padding: 16px;
                border-radius: 7px;
                display: flex;
                flex-flow: column;

                >h4 {
                    color: white;
                    font-size: 14px;
                    margin-top: auto;
                }

                >p {
                    color: white;
                    font-size: 14px;
                }

                @media (max-width: 600px) {
                    padding: 8px;

                    >h4, >p {
                        font-size: 10px;
                    }
                }
            }
        }
    }
}