.header {
    background-color: white;
    padding: 100px;

    @media (max-width: 600px) {
        padding: 50px;
    }

    >.inner {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 50px;

        >.content {
            flex: 1 1 300px;

            >h2,
            >p {
                color: var(--color-purple);
                margin-bottom: 20px;

                >.important {
                    color: var(--primary-color);
                }

                @media (max-width: 600px) {
                    text-align: center;
                }
            }
        }

        >.image {
            flex: 1 1 300px;

            >img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}

.about {
    padding: 150px;
    position: relative;
    // margin-bottom: 30px;
    margin-top: 30px;
    // background-color: white;
    &::before {
        content: '';
        background-color: #F3F3F3;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: skewY(-2deg);
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        z-index: -1;
    }
    &::after {
        content: '';
        background-color: #F3F3F3;
        position: absolute;
        top: 50%;
        bottom: -30px;
        left: 0;
        right: 0;
        z-index: -1;
    }

    @media (max-width:600px) {
        padding: 150px 50px;
    }

    >.head {
        max-width: 1200px;
        margin: 0 auto;
        margin-bottom: 100px;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 30px 50px;

        >h3 {
            color: var(--color-purple);
            flex: 1 1 200px;
        }
        p {
            flex: 4 1 300px;
            color: var(--color-purple);
        }

        @media (max-width:600px) {

            >h3,
            >p {
                text-align: center;
            }
        }
    }

    >.items {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        max-width: 1200px;
        margin: 0 auto;
        gap: 100px;

        >* {
            flex: 0 1 300px;
            height: 300px;
            object-fit: cover;
            border-radius: 30px;
        }
        >:first-of-type {
            display: none;
        }

        @media (max-width: 600px) {
            gap: 30px 20px;
            >* {
                width: 0%;
                flex: 1 1 70px;
                height: 100px;
                border-radius: 16px;
            }
            >:first-of-type {
                display: block;
                flex: 1 1 100%;
                object-position: center;
                width: 100%;
                height: 260px;
            }
        }
    }
}

.ourMission {
    // background-color: #994AD8;
    padding: 150px;
    position: relative;
    // margin-bottom: 30px;
    // margin-top: 30px;
    // background-color: white;
    &::before {
        content: '';
        background-color: #994AD8;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: skewY(2deg);
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        z-index: -1;
    }
    &::after {
        content: '';
        background-color: #994AD8;
        position: absolute;
        top: 50%;
        bottom: -30px;
        left: 0;
        right: 0;
        z-index: -1;
    }

    @media (max-width:600px) {
        padding: 150px 50px;
    }

    >.head {
        max-width: 1200px;
        margin: 0 auto;
        margin-bottom: 100px;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 30px 50px;

        >h3 {
            color: white;
            flex: 1 1 200px;
        }

        >p {
            color: white;
            flex: 4 1 300px;
        }

        @media (max-width:600px) {

            >h3,
            >p {
                text-align: center;
            }
        }
    }

    >.items {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        max-width: 1200px;
        margin: 100px auto 200px;
        gap: 25px;

        @media (max-width: 600px) {
            justify-content: center;
        }

        >.card {
            display: flex;
            flex-flow: column;
            align-items: center;
            gap: 25px;

            >h4 {
                color: white;
            }

            >.list {
                padding: 25px;
                border-radius: 20px;
                background-color: white;
                width: 200px;
                flex: 1;
                display: flex;
                flex-flow: column;
                align-items: center;
                justify-content: center;

                >h3,
                >h4 {
                    color: var(--color-purple);
                    text-align: center;
                }

                >h4 {
                    font-size: 14px;
                    margin-top: 5px;
                }
            }
        }
    }
}


.ourTeam {
    // background-color: white;
    padding: 150px;
    position: relative;
    // margin-bottom: 30px;
    margin-top: -30px;
    // background-color: white;
    &::before {
        content: '';
        background-color: white;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: skewY(-2deg);
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        z-index: -1;
    }
    &::after {
        content: '';
        background-color: white;
        position: absolute;
        top: 50%;
        bottom: -30px;
        left: 0;
        right: 0;
        z-index: -1;
    }

    @media (max-width:600px) {
        padding: 150px 50px;
    }

    >.head {
        max-width: 1200px;
        margin: 0 auto;
        margin-bottom: 100px;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 30px 50px;

        >h3 {
            color: var(--color-purple);
            flex: 1 1 200px;
        }

        >p {
            color: var(--color-purple);
            flex: 4 1 300px;
        }

        @media (max-width:600px) {

            >h3,
            >p {
                text-align: center;
            }
        }
    }

    >.items {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        max-width: 1200px;
        margin: 0 auto;
        gap: 100px;

        >.card {
            position: relative;
            flex: 0 1 300px;
            height: 300px;
            border-radius: 25px;
            overflow: hidden;

            >.top {
                display: flex;
                flex-flow: column;
                background-color: #994AD8;
                height: 100%;

                >.image {
                    flex: 1;
                    overflow: hidden;

                    >img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: top center;
                    }
                }

                >.info {
                    padding: 10px;
                    >h4 {
                        color: white;
                        font-size: 16px;
                        text-align: center;
                        margin-bottom: 8px;
                    }

                    >h5 {
                        color: white;
                        font-size: 14px;
                        text-align: center;
                    }
                }

            }
            >.overlay {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 10px;
                background-color: rgba(255, 255, 255, 0.6);
                backdrop-filter: blur(5px);
                transform: translateY(100%);
                transition: transform .1s;
            }
            &:hover {
                >.overlay {
                    transform: translateY(0);
                }
                
            }
        }

        @media (max-width: 600px) {
            gap: 25px;
            >.card {
                position: relative;
                flex: 0 1 200px;
                height: 200px;
                border-radius: 25px;
                overflow: hidden;
            }
        }
    }
}