.header {
    background-color: white;
    padding: 100px;

    @media (max-width: 600px) {
        padding: 50px;
    }

    >.inner {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 50px;

        >.content {
            flex: 1 1 300px;

            >h2,
            >p {
                color: var(--color-purple);
                margin-bottom: 20px;

                >.important {
                    color: var(--primary-color);
                }

                @media (max-width: 600px) {
                    text-align: center;
                }
            }

            >.row {
                display: flex;
                flex-flow: row wrap;
                justify-self: flex-start;
                gap: 20px;
                margin-top: 25px;
            }
        }

        >.image {
            flex: 1 1 300px;
            display: flex;
            justify-content: center;
            align-items: center;

            >img {
                width: 80%;
                height: 80%;
                object-fit: contain;
            }
        }

    }
}

.info {
    padding: 150px 100px;
    position: relative;
    margin-top: 30px;
    &::before {
        content: '';
        background-color: #F3F3F3;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: skewY(-2deg);
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        z-index: -1;
    }
    &::after {
        content: '';
        background-color: #F3F3F3;
        position: absolute;
        top: 50%;
        bottom: -30px;
        left: 0;
        right: 0;
        z-index: -1;
    }

    >.inner {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        gap: 50px;
        max-width: 1200px;
        margin: 0 auto;

        @media (max-width: 600px) {
            justify-content: center;
        }

        >.item {
            flex: 0 0 220px;
            height: 220px;
            background-color: white;
            padding: 25px;
            border-radius: 25px;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
            gap: 10px;

            >* {
                text-align: center;
                color: var(--color-purple);
            }
        }
    }
}


.ourServices,
.ourProcess {
    // background-color: #994AD8;
    padding: 150px;
    position: relative;
    margin-bottom: -30px;
    margin-top: 30px;
    // background-color: white;
    &::before {
        content: '';
        background-color: #994AD8;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: skewY(2deg);
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        z-index: -1;
    }
    &::after {
        content: '';
        background-color: #994AD8;
        position: absolute;
        top: 50%;
        bottom: -30px;
        left: 0;
        right: 0;
        z-index: -1;
    }

    @media (max-width:600px) {
        padding: 150px 50px;
    }

    >.head {
        max-width: 1200px;
        margin: 0 auto;
        margin-bottom: 100px;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 30px 50px;

        >h3 {
            color: white;
            flex: 1 1 200px;
        }

        >p {
            color: white;
            flex: 4 1 300px;
        }

        @media (max-width:600px) {

            >h3,
            >p {
                text-align: center;
            }
        }
    }

    >.items {
        display: flex;
        flex-flow: column;
        max-width: 1200px;
        margin: 100px auto;
        gap: 50px;

        >.card {
            display: flex;
            flex-flow: row nowrap;
            gap: 30px;

            >.content {
                flex: 1;
                background-color: white;
                border-radius: 20px;
                padding: 20px;
                display: flex;
                flex-flow: column;

                >* {
                    color: var(--color-purple);
                }

                >p {
                    margin-bottom: 10px;
                }

                >h5 {
                    font-weight: 500;
                    margin-top: auto;
                    margin-bottom: 5px;
                }

                >.proj {
                    display: flex;
                    flex-flow: row wrap;
                    gap: 10px;


                    >.projItem {
                        background-color: #994AD8;
                        color: white;
                        padding: 10px 17px;
                        border-radius: 7px;
                        font-size: 12px;
                        display: block;
                        text-decoration: none;
                    }
                }

                @media (max-width: 600px) {
                    align-items: center;
                    text-align: center;
                    gap: 10px;

                    >.proj {
                        justify-content: center;
                    }
                }
            }

            >.image {
                // max-height: 200px;
                flex: 0 0 230px;
                background-color: white;
                border-radius: 20px;
                padding: 40px;

                >img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }

                @media (max-width: 600px) {
                    display: none;
                }
            }
        }
    }
}

.ourProcess {
    margin-top: 0;
    &::before {
        content: '';
        background-color: #F3F3F3;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: skewY(-2deg);
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        z-index: -1;
    }
    &::after {
        content: '';
        background-color: #F3F3F3;
        position: absolute;
        top: 50%;
        bottom: -30px;
        left: 0;
        right: 0;
        z-index: -1;
    }

    >.head {

        >h3,
        >p {
            color: var(--color-purple);
        }
    }
}