@mixin products($color: black) {
    width: 100%;
    >header {
        background-color: $color;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border-radius: 20px 10px 0 0;

        >h4 {
            color: white;
        }
    }

    >.products {
        display: flex;
        flex-flow: row wrap;
        gap: 20px;
        margin: 10px 0;
        justify-content: flex-start;
        position: relative;

        >* {
            // container max width / max children
            flex: 0 1 calc(800px / 3);
        }
    }
}

.error {
    min-height: 100px;
    position: relative;
}

.marketingMaterialProducts, .searchResultProducts {
    @include products(var(--primary-color));
}

.officeStationaryProducts {
    @include products(#707070);
}

.birthdayPackageProducts {
    @include products(#BF0A83);
}

.moreProducts {
    @include products(#707070);
    margin-bottom: 50px;
    >header {
        border-radius: 0;
        >h4 {
            max-width: 900px;
            width: 100%;
            margin: 0 auto;
        }
    }
    >.products {
        max-width: 900px;
        width: 100%;
        margin: 0 auto;
    }
}


.bestSellingProducts {
    >header {
        background-color: var(--primary-color);
        padding: 10px 10px 40px;
        border-radius: 30px 10px 0 0;

        >h4 {
            color: white;
            font-weight: 400;
            text-align: right;
            font-size: 18px;
        }
    }

    >.content {
        margin-top: -20px;
        display: flex;
        flex-flow: column;
        gap: 16px;
    }
}

.clearanceSalesProducts {
    padding: 50px 30px;
    box-shadow: 0 -10px 30px rgba(0, 0, 0, 8%);
    background-color: white;

    >.inner {
        max-width: 900px;
        margin: 0 auto;

        >header {
            >h4 {
                color: var(--primary-color);
            }

            >nav {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;
                overflow: auto;
                gap: 10px;
                margin: 20px 0;

                >button {
                    cursor: pointer;
                    background-color: transparent;
                    border: none;
                    padding: 5px;
                    font-weight: 700;
                    font-size: 16px;
                    color: #707070;

                    &.active {
                        color: var(--primary-color);
                        position: relative;

                        &::after {
                            content: "";
                            position: absolute;
                            background-color: var(--primary-color);
                            height: 2px;
                            width: 15px;
                            left: 5px;
                            bottom: 0;
                        }
                    }
                }
            }
        }

        >.content {
            display: flex;
            flex-flow: row nowrap;

            >.display {
                flex: 4 1 300px;
                display: flex;
                flex-flow: column;

                >img {
                    height: 250px;
                    object-fit: cover;
                    border-radius: 25px;
                }

                >.mini_bannner {
                    display: flex;
                    flex-flow: row nowrap;
                    margin-top: 20px;
                    gap: 20px;
                    overflow: auto;

                    >img {
                        height: 75px;
                        width: 120px;
                        object-fit: cover;
                        border-radius: 15px;
                    }
                }
            }

            >.discount {
                flex: 1 1 100px;
                display: flex;
                flex-flow: column;
                align-items: center;
                justify-content: center;
                gap: 30px;
                >h2 {
                    font-size: 18px;
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: flex-start;
                    line-height: 2;
                    gap: 5px;
                    color: var(--secondary-color);
                    >span {
                        font-size: 35px;
                    }
                }
            }
        }
    }
}