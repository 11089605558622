.mainNav {
    padding: 10px 30px;
    // margin-bottom: 5px;
    // box-shadow: 0 6px 18px #00000026;
    border-bottom: 1px solid #00000026;
    position: relative;
    background-color: white;

    @media (max-width: 600px) {
        padding: 15px 30px;
    }

    >.inner {
        max-width: 1400px;
        margin: 0 auto;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        gap: 50px;

        .logo {
            height: 50px;
            object-fit: contain;
            cursor: pointer;
        }

        .mobileLogo {
            display: none;
            flex-flow: row nowrap;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            >img {
                height: 50px;
                object-fit: contain;
            }
            >div {
                color: var(--color-purple);
                font-weight: 700;
                font-size: 16px;
            }
        }

        @media (max-width: 600px) {
            .logo {
                display: none;
            }
            .mobileLogo {
                display: flex;
            }
        }

        .mobileMenu {
            width: 35px;
            height: 35px;
            border: none;
            background-color: transparent;
            border-radius: 5px;
            display: none;

            >* {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
                color: #0ABF8C;
            }

            &:hover {
                background-color: rgba(10, 191, 140, .2);
            }

            @media (max-width: 600px) {
                display: block;
            }
        }

        .searchField {
            height: 50px;
            max-width: 800px;
            width: 100%;
            display: flex;
            flex-flow: row nowrap;
            border: 2px solid #0ABF8C;
            border-radius: 10px;
            overflow: hidden;

            >input {
                flex: 1;
            }

            >.button {
                font-size: 16px;
                font-weight: 700;
                padding: 0 20px;
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                gap: 10px;
                border: none;
                background-color: white;
                text-decoration: none;
                cursor: pointer;


                &.menu {
                    background-color: #0ABF8C;
                    color: white;
                }

                &.search {
                    border-left: 2px solid #0ABF8C;
                    color: #0ABF8C;
                }

                >img {
                    width: 25px;
                    height: 25px;
                }

            }

            >input {
                min-width: 0%;
                border: none;
                font-size: 20px;
                padding: 14px 20px;
                outline: none;
            }
        }

        .secondaryBtn {
            display: flex;
            flex-flow: row nowrap;
            gap: 20px;

            >.button {
                border: none;
                white-space: nowrap;
                display: flex;
                align-items: center;
                gap: 10px;
                background-color: white;
                font-size: 16;
                font-weight: 700;
                color: #0ABF8C;
                text-decoration: none;
                position: relative;

                >img {
                    width: 25px;
                    height: 25px;
                }

                >.overlay {
                    position: absolute;
                    left: 10px;
                    top: -7px;
                    width: 20px;
                    height: 20px;
                    background-color: var(--secondary-color);
                    border-radius: 50%;
                    font-size: 10px;
                    color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .portfolioNavs {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 25px;

            >a {
                text-decoration: none;
                text-transform: uppercase;
                color: var(--color-purple);
                font-size: 14px;
                font-weight: 600;
                padding: 10px 10px;
                border-radius: 7px;
                transition: all .3s;

                &:hover {
                    background-color: rgba(57, 0, 104, 0.1);
                }

                &.linkType1 {
                    background-color: var(--primary-color);
                    color: white;

                    &:hover {
                        background-color: #E2FFF7;
                        color: var(--primary-color);
                    }
                }

                &.linkType2 {
                    background-color: #E2FFF7;
                    color: var(--primary-color);

                    &:hover {
                        background-color: var(--primary-color);
                        color: white;
                    }
                }
            }
        }

        @media (max-width: 600px) {
            .searchField {
                display: none;
            }

            .secondaryBtn {
                display: none;
            }

            .portfolioNavs {
                display: none;
            }

            .logo {
                height: 40px;
            }
        }
    }

    >.menu {
        position: fixed;
        display: flex;
        flex-flow: row wrap;
        gap: 0 5%;
        left: 0;
        right: 0;
        top: 70px;
        height: calc(100vh - 70px);
        z-index: 10;
        background-color: var(--background-color);
        padding: 20px 30px;

        >.category {
            flex: 2 1 500px;

            >.head>h5 {
                color: var(--primary-color);
            }

            >.body>.item {
                flex: 1 1 300px;
            }

            >.body>.item>label {
                background-color: #9F9E9E;
            }
        }

        >.designs {
            flex: 1 1 300px;

            >.head>h5 {
                color: var(--secondary-color);
            }

            >.body>.item {
                flex: 1 1 300px;
            }

            >.body>.item>label {
                background-color: var(--secondary-color);
            }
        }

        >* {
            background-color: white;
            border-radius: 20px;
            padding: 20px;
            display: flex;
            flex-flow: column;
            height: 100%;

            >.head {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;
            }

            >.body {
                padding: 10px 0;
                display: flex;
                flex-flow: row wrap;
                gap: 40px 9%;
                overflow-y: auto;

                // flex: 1;
                >.item {
                    >label {
                        padding: 5px 10px;
                        color: white;
                        font-size: 16px;
                        border-radius: 10px;
                    }

                    >.content {
                        margin-top: 16px;
                        display: flex;
                        flex-flow: row wrap;
                        gap: 16px 30px;

                        >.link {
                            color: var(--color-purple);
                            text-decoration: none;
                        }
                    }
                }
            }
        }

    }
}


.mobileAppbar {
    z-index: 10;
    position: fixed;
    top: 0;
    height: 100vh;
    left: 0;
    right: 0;
    background-color: white;
    display: flex;
    flex-flow: column;

    >header {
        margin: 15px 30px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        gap: 50px;

        .logo {
            height: 50px;
            object-fit: contain;
            cursor: pointer;
        }

        >.close {
            color: var(--color-purple);
            height: 35px;
            width: 35px;
        }
    }

    >.body {
        flex: 1;
        overflow-y: auto;
        padding: 15px 30px;
        >.btns {
            display: flex;
            flex-flow: row nowrap;
            gap: 30px;
            margin: 30px 0 70px;

            a {
                min-width: 0;
            }
        }

        >.links {
            display: flex;
            flex-flow: row wrap;
            gap: 70px;
            * {
                color: var(--color-purple) !important;
                text-decoration: none;
            }

            >div {
                max-width: fit-content;
                display: flex;
                flex-flow: column;
                gap: 15px;

                h5 {
                    height: 30px;
                    margin-bottom: 10px;
                    color: white;
                }
            }
        }
    }
}