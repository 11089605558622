.header {
    background-color: white;
    padding: 100px;

    @media (max-width: 600px) {
        padding: 50px;
    }

    >.inner {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        gap: 50px;

        >.content {
            flex: 1 1 300px;

            >h2,
            >p {
                color: var(--color-purple);
                margin-bottom: 20px;

                @media (max-width: 600px) {
                    text-align: center;
                }
            }
        }

        >.image {
            flex: 1 1 300px;

            >img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}

.info {
    padding: 150px 100px;
    position: relative;
    margin-bottom: -30px;
    margin-top: 30px;

    // background-color: white;
    &::before {
        content: '';
        background-color: #F3F3F3;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        transform: skewY(-2deg);
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        z-index: -1;
    }

    &::after {
        content: '';
        background-color: #F3F3F3;
        position: absolute;
        top: 50%;
        bottom: -30px;
        left: 0;
        right: 0;
        z-index: -1;
    }

    @media (max-width: 600px) {
        padding: 100px 50px;
    }

    >.inner {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-flow: column;
        gap: 200px;

        >.item {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;

            &:nth-of-type(2n) {
                flex-direction: row-reverse;
            }

            gap: 50px;

            >.content {
                flex: 1 1 300px;

                >h3 {
                    color: var(--color-purple);
                    margin-bottom: 20px;
                }

                >p {
                    color: var(--color-purple);
                    margin-bottom: 25px;
                }

                @media (max-width:600px) {
                    display: flex;
                    flex-flow: column;
                    * {
                        margin-left: auto;
                        margin-right: auto;
                        text-align: center;
                    }
                }
            }

            >.image {
                flex: 1 1 300px;

                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    object-position: center center;
                }
            }
        }
    }
}

