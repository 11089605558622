.button {
    border: none;
    height: 50px;
    padding: 0 30px;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    min-width: 250px;
    border-radius: 10px;
    text-decoration: none;
    cursor: pointer;

    &.orange {
        background-color: var(--secondary-color);
        color: white;
        box-shadow: 0 10px 20px #FFF3BE;
    }

    &.green {
        background-color: var(--primary-color);
        color: white;
        box-shadow: 0 10px 20px rgba(10, 191, 140, 0.2);
    }

    &.greenShade {
        background-color: rgba(10, 191, 140, .15);
        color: var(--primary-color);
        box-shadow: 0 10px 20px rgba(10, 191, 140, 0.2);
    }

    &.white {
        background-color: white;
        color: black;
        box-shadow: 0 10px 20px #78787820;
    }

    @media (max-width: 600px) {
        font-size: 14px;
    }
}

.outsetButton {
    border: 1px solid transparent;
    height: 40px;
    padding: 0 30px;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    border-radius: 10px;
    text-decoration: none;
    box-shadow: -8px 8px 0 #E3E3E3;
    transition: all .3s;
    cursor: pointer;

    &.orange {
        background-color: var(--secondary-color);
        color: white;

        &:hover {
            box-shadow: 0 0 0 transparent;
            color: var(--secondary-color);
            background-color: white;
            border-color: var(--secondary-color);
        }
    }

    &.green {
        background-color: var(--primary-color);
        color: white;

        &:hover {
            box-shadow: 0 0 0 transparent;
            color: var(--primary-color);
            background-color: white;
            border-color: var(--primary-color);
        }
    }

    &.white {
        background-color: white;
        color: var(--color-purple);
        border-color: #E3E3E3;

        &:hover {
            box-shadow: 0 0 0 transparent;
            color: white;
            background-color: var(--color-purple);
            border-color: var(--color-purple);
        }
    }

    @media (max-width: 600px) {
        font-size: 14px;
    }

}

.flatButton {
    border: none;
    height: 40px;
    padding: 0 10px;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    border-radius: 5px;
    text-decoration: none;
    cursor: pointer;

    &.orange {
        background-color: var(--secondary-color);
        color: white;
        // box-shadow: 0 10px 20px #FFF3BE;
    }

    &.white {
        background-color: white;
        color: orange;
        // box-shadow: 0 10px 20px #78787820;
    }

    &.purple {
        background-color: white;
        color: var(--color-purple);
        // box-shadow: 0 10px 20px #78787820;
    }

    @media (max-width: 600px) {
        font-size: 14px;
    }
}