%common {
    max-width: 900px;
    background-color: white;
    margin: 50px auto;
    padding: 30px;
    border-radius: 20px;

    >h3 {
        color: var(--primary-color);
        text-align: center;
    }

    >h5 {
        margin-top: 10px;
        text-align: center;
    }

    >.proceed {
        margin-top: 50px;
        display: flex;
        flex-flow: row wrap;
        gap: 20px 30px;
        justify-content: center;
    }
}

.variationCard {
    @extend %common;

    >.basicInfo {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: flex-end;
        >h4, >h5 {
            color: var(--primary-color);
            margin-top: 20px;
            margin-bottom: 10px;
        }
    }
    >.variations {
        display: flex;
        flex-flow: row wrap;
        gap: 10px 5%;

        >* {
            flex: 1 1 40%;
        }
    }

    >.total {
        background-color: white;
        color: var(--secondary-color);
        padding: 20px;
        box-shadow: 0 10px 20px #FFF3BE;
        margin-top: 20px;
        max-width: 400px;
        margin-left: auto;
        font-size: 20px;
        font-weight: bold;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        position: relative;
        >.discount {
            position: absolute;
            left: -5px;
            top: -5px;
            font-size: 9px;
            font-weight: 400;
            background-color: var(--primary-color);
            color: white;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
        }
        >.counter {
            display: flex;
            flex-flow: row nowrap;
            align-items: stretch;
            >button {
                width: 25px;
                border: 1px solid var(--secondary-color);
            }
            >input {
                flex: 1;
                max-width: 100px;
                font-size: 14px;
                font-weight: 600;
                letter-spacing: 1.5px;
                border: 1px solid var(--secondary-color);
                padding: 0 5px;
            }
        }
    }


}

.orderCard {
    @extend %common;

    >.basicInfo {
        margin: 30px 0;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        gap: 30px 30px;

        >* {
            flex: 0 1 250px;
        }

        >.span1 {
            flex: 1 1 100%;
            >.field {
                display: flex;
                flex-flow: row nowrap;
                gap: 5px;
                >input {
                    flex: 1;
                    padding: 10px;
                }
            }
        }
    }

    >.fee,
    >.feeT {
        background-color: #F3F3F3;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
    }

    >.feeT {
        margin-top: 10px;

        >* {
            color: var(--secondary-color);
        }
    }
}

.paymentCard {
    @extend %common;

    >.paymentMethod {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        margin: 30px 0;
        gap: 25px;

        >* {
            width: 200px;
            height: 100px;
            background-color: var(--primary-color);
            padding: 20px;
            border-radius: 10px;
            display: flex;
            flex-flow: column;
            opacity: .8;
            position: relative;

            >img {
                width: 80%;
                height: auto;
                max-height: 40px;
                margin: auto auto;
            }

            >.selectedIc {
                position: absolute;
                top: -5px;
                right: -5px;
                width: 25px;
                height: 25px;
                margin: 0 0;
            }

            &:hover {
                opacity: 1;
            }
        }
    }

    >.paymentGateway {
        display: flex;
        flex-flow: row wrap;
        gap: 10px 30px;

        >* {
            flex: 1 1 300px;
            display: flex;
            flex-flow: column;
            gap: 10px;
        }
    }

    .row {
        display: flex;
        flex-flow: row nowrap;
        gap: 10px;

        >* {
            flex: 1;
        }
    }
}



.dropdown {
    position: relative;

    >.dropdownSelect {
        display: flex;
        flex-flow: row nowrap;
        // border-radius: 5px;

        >.selected {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            flex: 1;
            box-shadow: 1px 5px 10px rgba(0, 0, 0, .1);
        }

        >.price {
            padding: 10px;
            max-width: 100px;
            width: 100%;
            font-size: 16px;
            color: var(--color-black);
            line-height: 1.5;
            margin-left: 10px;
            box-shadow: 1px 5px 10px rgba(0, 0, 0, .1);
        }
    }

    >.label {
        text-transform: uppercase;
        font-size: 12px;
        color: var(--primary-color);
    }

    >.dropdownOptions {
        box-shadow: 1px 5px 10px rgba(0, 0, 0, .1);
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        border-top: 1px solid var(--primary-color);
        position: absolute;
        width: 100%;
        background-color: white;
        z-index: 5;

        .option, .subOption {
            padding: 15px 10px;
            font-size: 14px;
            border-bottom: 1px solid #cacaca;
            display: flex;
            flex-flow: row nowrap;

            &:last-of-type {
                border-bottom: none;
            }

            &:hover {
                background-color: #cacaca;
            }

            >.selected {
                flex: 1;
            }

            >.price {
                max-width: 100px;
                width: 100%;
                padding-left: 20px;
            }
        }
        .option {
            background-color: var(--primary-color);
            &:hover {
                background-color: var(--primary-color);
            }
        }
    }
}

.displayAllOptions {
    >.label {
        display: block;
        font-size: 14px;
        color: var(--color-black);
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 10px;
    }
    .header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        background-color: var(--primary-color);
        padding: 7px 10px;
        color: white;
        font-weight: 600;
        font-size: 16px;
    }
    .option {
        display: flex;
        flex-flow: column;
        >.row {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            gap: 10px;
            border-bottom: 1px solid var(--primary-color);
            padding: 10px 0;
            >label {
                flex: 0 0 150px;
                display: block;
                font-weight: 500;
                font-size: 14px;
                color: var(--color-black);
            }
            >.rowItems {
                    flex: 1;
                    display: flex;
                    flex-flow: row nowrap;
                    overflow-x: auto;
                    overflow-y: hidden;
                    gap: 20px;
                    list-style-type: none;
                    >li {
                        margin-left: 0px;
                        font-size: 14px;
                        color: var(--color-black);
                        border: 1px solid var(--primary-color);
                        padding: 3px 5px;
                        border-radius: 5px;
                        cursor: pointer;

                        &.active {
                            background-color: var(--primary-color);
                            color: white;
                        }
                    }
            }
        }
    }
}


.inputField {

    >.field {
        display: flex;
        flex-flow: row nowrap;
        // border-radius: 5px;

        >input {
            border: none;
            height: 35px;
            width: 100%;
            box-shadow: 1px 5px 10px rgba(0, 0, 0, .1);
            font-size: 16px;
            padding: 0 10px;
            letter-spacing: 1.2px;
        }

        >.logo {
            line-height: 1.5;
            margin-left: 10px;
            box-shadow: 1px 5px 10px rgba(0, 0, 0, .1);
            padding: 5px 10px;

            >* {
                width: 100%;
            }
        }
    }

    >.label {
        text-transform: uppercase;
        font-size: 12px;
        color: var(--primary-color);
    }
}