.auth {
	min-height: 100%;
	padding: 50px 30px;
	background: url(./assets/bg.svg) center center / cover no-repeat, linear-gradient(to bottom, transparent, white 100%);

	>.inner {
		max-width: 1000px;
		margin: 0 auto;
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;

		>article {
			max-width: 300px;
			margin-top: 30px;

			h2 {
				color: var(--primary-color);
				margin-bottom: 12px;
			}
		}

		>form {
			border-radius: 40px;
			max-width: 500px;
			width: 100%;
			padding: 30px;
			background-color: white;
			box-shadow: 0 -10px 30px #70707020;

			>* {
				max-width: 350px;
				margin: 0 auto;
				text-align: center;
			}

			h3 {
				color: var(--primary-color);
				margin-bottom: 5px;
			}

			.formFields {
				margin: 50px;
				> *{
					margin: 15px 0;
				}
			}
			.btns {
				margin-top: 40px;
				display: flex;
				flex-flow: column;
				align-items: center;
				gap: 30px;
			}
		}

		>.image {
			max-width: 500px;
			flex: 1 1 300px;
			>img {
				height: 100%;
				width: 100%;
			}
		}
	}

	>.row {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;
		padding: 0 30px;

		>.buttons {
			display: flex;
			flex-flow: row nowrap;
			height: 50px;
			width: 100%;
			max-width: 300px;
			background: linear-gradient(115deg, white 50%, var(--secondary-color) 50%);
			border-radius: 10px;
			>* {
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				vertical-align: middle;
				color: white;
				text-decoration: none;
				font-size: 18px;
				font-weight: 500;

				&:first-of-type {
					color: var(--secondary-color);
				}
			}
		}
	}
}