.printHomePage {
    >header {
        max-width: 1000px;
        margin: 0 auto;
        padding: 50px 30px;
        >.inner {
            display: flex;
            flex-flow: row nowrap;
            gap: 50px;
            >.content {
                flex: 1.3 1;
                .orange {
                    color: var(--secondary-color);
                }
                >p {
                    margin: 30px 0;
                }
            }
            >.image {
                flex: 2 1;
                >img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

    }
    >.row {
        display: flex;
        flex-flow: row nowrap;
        max-width: 1400px;
        margin: 0 auto;
        padding: 50px 30px;
        gap: 20px;

        >.aside {
            width: 140px;
        }
        >.content {
            flex: 1 1 400px;
            position: relative;
        }
    }
}