.footer {
    background-color: var(--primary-color);
    padding: 80px 30px 50px;

    &.type2 {
        background-color: var(--color-purple);
    }

    >.navs {
        max-width: 1400px;
        margin: 0 auto;
        display: flex;
        flex-flow: row wrap;
        gap: 70px 30px;

        >section {
            flex: 1 1 250px;
            display: flex;
            flex-flow: column;
            gap: 15px;
            &.rows {
                flex-flow: row wrap;
                gap: 30px;
            }
            >img {
                height: 30px;
                width: fit-content;
                margin-bottom: 20px;
            }

            >div {
                display: flex;
                flex-flow: column;
                gap: 15px;
            }

            h5 {
                height: 30px;
                margin-bottom: 20px;
                color: white;
                > a {
                    font-weight: 700;
                    font-size: 14px;
                }

                @media (max-width: 600px) {
                    margin-bottom: 10px;
                }
            }

            a,p {
                display: flex;
                flex-flow: row nowrap;
                // gap: 10px;
                color: white;
                font-weight: 400;
                font-size: 14px;
                text-decoration: none;

                >span {
                    width: 30px;
                }
            }

            >.newsletter {
                display: flex;
                flex-flow: row nowrap;
                gap: 20px;
                >input {
                    flex: 1;
                    height: 40px;
                    background-color: transparent;
                    color: white;
                    border: 1px solid #00FFB7;
                    padding: 0 20px;
                    border-radius: 4px;
                    &::placeholder {
                        color: white;
                    }

                    &.white {
                        border-color: white;
                    }
                    &.green {
                        border-color: #00FFB7;
                    }
                }
            }
        }
    }
}