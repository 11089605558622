.productDescriptionPage {
    padding: 50px 30px;
    background-color: #F3F3F3;

    >.inner {
        max-width: 900px;
        margin: 0 auto;
        display: flex;
        flex-flow: row nowrap;

        >.main {
            flex: 1 1 300px;

            >.productFace {
                >.priceTag {
                    background-color: white;
                    color: var(--primary-color);
                    width: fit-content;
                    margin-left: auto;
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: baseline;
                    gap: 10px;
                    padding: 5px 20px 35px;
                    margin-bottom: -30px;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;

                    >h6 {
                        text-decoration: line-through;
                    }
                }

                >.faceCarousel {
                    height: 300px;
                    border-radius: 20px;
                    overflow: hidden;

                    >img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }

                >.productReview {
                    background-color: white;
                    width: fit-content;
                    margin-right: auto;
                    display: flex;
                    flex-flow: row nowrap;
                    align-items: center;
                    gap: 10px;
                    padding: 35px 20px 5px;
                    margin-top: -30px;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;

                    >h5 {
                        color: var(--primary-color);
                        text-decoration: underline;
                    }

                    //Rating system

                    >p {
                        font-size: 10px;
                        margin-top: 5px;
                        color: var(--secondary-color);
                    }
                }

                >.secondaryFace {
                    display: flex;
                    flex-flow: row nowrap;
                    overflow: auto;
                    gap: 20px;
                    margin-top: 20px;
                    margin-bottom: 50px;

                    >* {
                        height: 75px;
                        width: 120px;
                        object-fit: cover;
                        border-radius: 15px;
                    }
                }
            }

            .textonlyField {
                padding: 20px 30px;

                >h4 {
                    margin: 10px 0;
                }
            }

            .reviews {
                .ratings {
                    display: flex;
                    flex-flow: row nowrap;
                    justify-content: space-between;
                    padding: 10px 30px;
                    color: var(--secondary-color);

                    >h3 {
                        font-weight: 500;
                    }

                    >div {
                        display: flex;
                        flex-flow: column;
                        align-items: center;
                    }
                }

                .linearRatings {
                    padding: 30px;
                    display: flex;
                    flex-flow: column;
                    gap: 10px;

                    >.line {
                        display: flex;
                        flex-flow: row nowrap;
                        gap: 10px;

                        opacity: 0.5;

                        &:hover {
                            opacity: 1;
                        }

                        >.col1,
                        >.col3 {
                            max-width: 50px;
                            width: 100%;
                            font-size: 14px;
                            color: #00E2A2;
                            font-weight: 800;
                        }

                        >.col2 {
                            flex: 1;
                            background-color: #00E2A2;
                        }

                        >.col3 {
                            text-align: right;
                        }
                    }
                }

                .commentField {
                    display: flex;
                    flex-flow: row nowrap;
                    gap: 20px;
                    padding: 0 30px 15px;

                    >.comment {
                        flex: 1;
                        >.head {
                            display: flex;
                            flex-flow: row nowrap;
                            align-items: center;
                            gap: 5px;

                            >h5 {
                                margin-right: 20px;
                            }
                            >h6 {
                                color: var(--secondary-color);
                            }
                        }
                    }

                    >.timeStamp {
                        font-size: 12px;
                        text-align: right;
                        color: var(--secondary-color);
                    }
                }
            }
        }

        >.aside {
            max-width: 200px;
            width: 100%;
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            gap: 20px;
            padding: 40px 20px 20px;
        }
    }
}

.rateSystem {
    margin-top: 5px;

    >* {
        font-size: 16px;
        color: var(--secondary-color);
    }
}

.rowCenter {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}