.container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px 30px;
    >h4 {
        color: var(--primary-color);
        margin-bottom: 20px;
    }

    >.row {
        display: flex;
        flex-flow: row wrap;
        gap: 20px;
    }
}